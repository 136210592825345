html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #f0f2f5 !important;
}

#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 80px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #f0f2f5;
}

label {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-form a {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1024px) {
  body {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }

  #root {
    display: block;
    //overflow-y: scroll;
  }
}
