.logo {
  color: #fff;
  vertical-align: center;
  max-width: 150px;

  .Logo_description {
    font-size: 12rem;
    font-size: 0.8rem;
    padding: 0;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-left: 30px;
    margin-top: -8px;
  }
}

.divi,
button {
  float: right;
}

header .ant-btn-primary {
  margin-left: 12px;
  border: solid 1px #fff;
  background-color: transparent;
}

/**
 * style logout btn
 */
.menuuser .ant-btn-primary {
  width: auto;
  border: 1px solid #d9d9d9;
}

.menuuser .ant-btn-primary:hover {
  width: auto;
  background: none;
  border-color: #40a9ff;
}

@media (max-width: 576px) {
  .logout-btn {
    display: none !important;
  }
}
