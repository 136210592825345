.layout-content-header,
.ant-layout-header,
.ant-layout-header{
  background: #fff ;
  h1 {
    text-align: center;
    background: transparent ;

  }
}
.wrapper .layout-app-content .ant-layout-header{
  background: none !important;
}

.login-form-fields {
  background: #fff;
  padding: 20px;
}

// form valid field
.has-success{
  input {
    border-color: green ;
  }
}

.ant-col{
  padding: 10px;
}

.important-fields {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-message {
  padding-top: 0px;
}

.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.login-form-fields-test {
  margin: auto;
}

span a{
  margin: 0;
}
