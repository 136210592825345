.ant-select-selection {
  background-color: transparent !important;
}

.menuuser {
  z-index: 9999999;
  float: right;
  border-right: none;
  height: 64px;
  display: flex;
  align-items: center;

  .ant-btn-primary {
    width: 130px;
    font-size: 1rem;
  }

  li {
    float: right;
    background: none;
    height: 60px !important;
  }
}

// language flags
.language-menu {
  display: block;
  background-color: transparent;
  text-align: right;

  span {
    color: #fff;
    margin: -4px 0px 0px 5px;
  }
}

.language-item .flag-image,
.language-menu .flag-image {
  width: 30px;
  max-height: 30px;
  line-height: 25px;
  margin-right: 5px;
  margin-top: -3px;
}
