.footer-content {
  align-items: center;
  text-align: center;
}
span {
  a {
    margin: 0px 5px 0px 5px;
    text-align: center;
  }
}
.page-footer {
  padding-bottom: 2.5rem;
  text-align: center;
}

.footer-logo {
  width: auto;
  height: 35px;
  margin-bottom: 9px;
  margin-right: 5px;
  display: inline-block;
}
.easy-text {
  margin-right: 10px;
  text-align: center;
}

// reset ant design with grid css
.ant-layout-footer {
  display: flex;
  box-sizing: border-box;
  padding: 24px 50px;
  padding: 0;
  line-height: 0.02em;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  justify-content: center; /* center text horizontally */
  align-items: center; /* center text vertically */
}

.footer-divider {
  display: none;
}

@media (max-width: 1024px) {
  .ant-layout-footer {
    height: auto;
  }

  .footer-divider {
    height: 3px;
    border: none;
    display: block !important;
  }
}
@media (min-width: 576px) {
  .ant-col-sm-0 {
    display: block;
  }
}
