.panel {
  background-color: white;
  padding: 10px 10%;
  margin: 10px 10%;
}

.centerContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
}