.btn-special span {
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 5px;
}



