@import '~antd/dist/antd.css';

.loading {
  background: url('https://de.wikipedia.org/wiki/Datei:Volkswagen_logo_2019.svg')
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.page-titel {
  text-align: center;
}

// form
.important-fields {
  padding-right: 15px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 20px;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 1;
  box-sizing: border-box;
}

.app-footer {
  border-top: solid 1px #ddd;
  grid-column: 1;
  grid-row: 3;
}
section {
  box-sizing: border-box !important;
  margin: 0 !important;
  //background: red !important;
}
.ant-layout {
  grid-column: 1;
  grid-row: 2;
  overflow: hidden;
  min-height: 100%;
  //background: orange;

  //overflow: hidden !important;
  h1 {
    margin-top: 20px;
  }
}

.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
  }


  .ant-layout {
    overflow: hidden;
    margin-top: 64px !important;
  }

  .ant-layout-header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
  }
}

.grid-container {
 /*  background-color: #eee; */
  padding: 10px;
  box-sizing: border-box;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.right {
  float: right;
}


// form
.ant-form-item {
  margin-bottom: 0;
  margin: 20px;
}
